import bgVideo from '../assets/bgVideo.mp4';
import phone from '../assets/phone.png';
import aboutImg from '../assets/shabnam-decor-about-image.jpg'
import email from '../assets/contact-mail.png'
import logo from '../assets/logo.png'

export default {
    bgVideo,
    logo,
    phone,
    aboutImg,
    email
};